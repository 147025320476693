<template>
    <div class="pt-2 pb-3 sentence-completion-click">
      <!-- <div class="question-text">
        {{ currentQuestion }}
      </div> -->
  
      <div class="d-flex justify-content-center mb-2">
          <div class="btn__expressions" v-if="this.currentQuestion._id">
            <span v-for="(e, eIndex) in expressions" :key="eIndex" class="">
                <span v-if="e.type == 'question'" @click="releaseOption(e, eIndex)">
                    <button v-if="e.expression != '__'" :class="`btn___${e.btn}`">
                        {{ e.expression }}
                    </button>
                    <span
                        v-else
                        :class="{'blinking-cursorx vertical-line': currentPlaceholder === eIndex}"
                    >
                        ____
                    </span>
                </span>
                <span v-else>
                    {{ e.expression }}
                </span>
            </span>
          </div>
      </div>
  
      <div class="btn__options">
        <div
          v-for="(o, oIndex) in options"
          :key="oIndex"
        >
          <button
            :class="`btn btn__${o.btn} text-white btn__${o.disabled}`"
            @click="handleOptionClick(o, oIndex)"
            :disabled="o.disabled == 'disabled'"
          >
            {{ o.option }}
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { BButton, BAlert, BSpinner } from "bootstrap-vue";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import Confetti from "../../../../public/Confetti.json";
  import Lottie from "vue-lottie";
  import _ from "lodash";
  import * as Helpers from "@/Helpers";
  
export default {
    components: {
        BButton,
        BAlert,
        BSpinner,
        ToastificationContent,
        Lottie,
    },
    data() {
        return {
            questionText: "",
            alertMessageToast: "",
            currentQuestionIndex: 0,
            Confetti,
            confettiOptions: {
                loop: false,
                autoplay: true,
                animationData: Confetti,
            },
            draggedOption: null,
            classes: ["blue", "green", "orange", "purple"],
            dragging: false,
            expressions: [],
            options: [],
            answers: [],
            Helpers,
            currentPlaceholder: 0,
        };
    },
    methods: {
        releaseOption(e, eIndex){
            if(e.optionIndex != undefined){
                this.$set(this.options, e.optionIndex, {
                    ...this.options[e.optionIndex],
                    disabled: null,
                });
            }

            if(eIndex != undefined){
                this.$set(this.expressions, eIndex, {
                    ...e,
                    expression: "__",
                    matched: false,
                });
            }
            
            const firstUnansweredQuestion = this.expressions.filter(
                (exp) => exp.type === "question" && exp.expression != "__"
            );
            this.currentPlaceholder = firstUnansweredQuestion.length > 0 ? eIndex : 0;
            

            // const firstUnansweredQuestion = this.expressions.find(
            //     (exp) => exp.expression === "__"
            // );
            // console.log('firstUnansweredQuestion=',JSON.stringify(this.expressions), JSON.stringify(firstUnansweredQuestion));
        },
        getExpressions() {
            if (this.currentQuestion.patterns && this.currentQuestion.patterns[0].expression) {
                const expression = this.currentQuestion.patterns[0].expression;
                const answer = this.currentQuestion.patterns[0].answer;

                // console.log(
                //     'splitStr1AndStr2=',
                //     answer,
                //     JSON.stringify(Helpers.splitStr1AndStr2(answer, Helpers.parseStringByUnderscore(expression))),
                //     JSON.stringify(Helpers.parseStringByUnderscore(expression))
                // );

                const exData = Helpers.parseStringByUnderscore(expression);
                const ansData = Helpers.splitStr1AndStr2(answer, exData);
                this.expressions = exData.filter(item => item.expression.trim() !== "");
                this.answers = ansData.filter(item => {
                    return typeof item.expression === "string" ? item.expression.trim() !== "" : true;
                });

                // const parts = this.Helpers.splitTextWithUnderscore(expression);
                // this.expressions = parts.map((element, index) => ({
                //     expression: element,
                //     type: element === "__" ? "question" : "option",
                // }));
                // this.answers = this.Helpers.splitStringAccordingToExpression(this.expressions, answer);
            
                this.currentPlaceholder = this.expressions.findIndex(
                    (e) => e.type === "question" && !e.matched
                );

              console.log('this.expressions=',JSON.stringify(this.expressions));
              console.log('this.answers=',JSON.stringify(this.answers));
              console.log('this.answer=',answer);
            //   console.log('this.expressions=',answer, JSON.stringify(this.expressions), JSON.stringify(this.Helpers.splitStringAccordingToExpression(this.expressions, answer)));
            }
        },
        getOptions() {
            // console.log('this.currentQuestion.patterns[0].optionsGroups=', JSON.stringify(this.currentQuestion.patterns[0].optionsGroups));
            if (this.currentQuestion.patterns && this.currentQuestion.patterns[0].optionsGroups && this.currentQuestion.patterns[0].optionsGroups.length > 0) {
                const optionsTitle = this.currentQuestion.patterns[0].optionsGroups[0].title;
                const optionsArray = optionsTitle.split(",").map(option => option.trim());

                this.options = _.shuffle(optionsArray).map((option, index) => ({
                    option,
                    btn: this.classes[index % this.classes.length],
                }));
            }
        },

        handleOptionClick(option, optionIndex) {
            this.$set(this.options, optionIndex, {
                ...this.options[optionIndex],
                disabled: 'disabled',
            });
            
            const questionIndex = this.expressions.findIndex(
                (e) => e.type === "question" && !e.matched
            );

            if (questionIndex !== -1) {
                this.$set(this.expressions, questionIndex, {
                    ...this.expressions[questionIndex],
                    expression: option.option,
                    matched: true,
                    btn: option.btn,
                    optionIndex: optionIndex,
                });

                this.currentPlaceholder = this.expressions.findIndex(
                    (e) => e.type === "question" && !e.matched
                );

                const allFilled = this.expressions.every((e) => e.type !== "question" || e.matched);
                // console.log('this.expressions=',JSON.stringify(this.expressions), JSON.stringify(allFilled), this.answers);
                // console.log('allFilled=',JSON.stringify(allFilled));
                // console.log('this.answers=',JSON.stringify(this.answers));

                if (allFilled) {
                    const isCorrect = this.expressions.every((e, index) => {
                        const answer = this.answers[index]?.expression;
                        return answer.includes(e.expression);
                    });

                    const getEmit = { ...this.currentQuestion, isCorrect };

                    if (!isCorrect) {
                        setTimeout(() => {
                            this.expressions.forEach((e, index) => {
                                if (e.type === "question") {
                                    this.$set(this.expressions, index, {
                                        ...e,
                                        expression: "__",
                                        matched: false,
                                    });
                                }
                            });
                            
                            this.currentPlaceholder = this.expressions.findIndex(
                                (e) => e.type === "question" && !e.matched
                            );

                            this.getOptions();
                        }, 500);
                    }

                    // console.log('getEmit=',getEmit)
                    this.$emit("get-question-response", getEmit);
                }
            }
        }

    },
    watch: {
        currentQuestion() {
            this.getExpressions();
            this.getOptions();
        },
    },
    mounted() {
        this.getExpressions();
        this.getOptions();
    },
    props: ["currentQuestion"],
};
</script>

<style>
.sentence-completion-click .btn__options {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
    justify-content: center;
}
.sentence-completion-click .btn__options .btn {
    padding-left: 2rem;
    padding-right: 2rem;
}
.sentence-completion-click .btn__expressions {
    display: flex;
    flex-wrap: wrap;
    gap: 0.3rem;
    padding: 1rem;
    font-size: clamp(1.25rem, 2vw, 2rem);
    background: #01c4b3;
    border: solid 2px #fff;
    border-radius: 10px;
}
.sentence-completion-click .btn__expressions button {
    padding-left: 1rem;
    padding-right: 1rem;
}
.sentence-completion-click .vertical-line {
    position: relative;
}
.sentence-completion-click .vertical-line:before {
    content: " ";
    position: absolute;
    bottom: 6px;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 2px;
    background: #fff;
}

.sentence-completion-click .vertical-line:before,
.sentence-completion-click .blinking-cursor {
    animation: blink 1s infinite;
}
  
@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.sentence-completion-click .btn__disabled {
    opacity: 0.5 !important;
}
</style>