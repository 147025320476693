<template>
    <div class="pt-2 pb-3 drag-and-drop">
    <!-- <div class="question-text">
      {{ currentQuestion }}
    </div> -->

    <div class="drag-and-drop-container">
      
      <div class="question-data">
        <div class="btn__expressions" v-if="this.currentQuestion._id">
          <draggable
            v-model="expressions"
            :sort="false"
            group="expressions"
            @start="dragging = true"
            @end="dragging = false"
            class="draggable-container"
            @change="onDragChange"
          >
            <div v-for="(e, eIndex) in expressions" :key="eIndex">
              <span
              >
                <button
                  v-if="e.type == 'option'"
                  :class="`btn btn__option`"
                >
                  {{ e.expression }}
                </button>
                <template v-else>
                  <button
                    v-if="e.expression != '__'"
                     @click="releaseOption(e, eIndex)"
                    :class="`btn btn__${e.btn} col-size-${optionMaxCharacterCount}`"
                    
                  >
                    {{ e.expression }}
                  </button>
                  <button
                    v-else
                    :class="`btn ${e.btn?'btn__'+e.btn:'btn__option'} col-size-${optionMaxCharacterCount}`"
                    @dragover.prevent
                    @drop="handleDrop(e, eIndex)">
                    ?
                  </button>
                </template>
              </span>
            </div>
          </draggable>
        </div>
    
        <div class="btn__options">
          <draggable
            v-model="options"
            :sort="false"
            group="options"
            @start="dragging = true"
            @end="dragging = false"
            class="draggable-container"
            @change="onDragChange"
          >
            <div
              v-for="(o, oIndex) in options"
              :key="oIndex"
            >
              <button
                :class="`btn btn__${o.btn} text-white btn__${o.disabled} col-size-${optionMaxCharacterCount}`"
                :draggable="true"
                @dragstart="startDrag(o, oIndex)"
                :disabled="o.disabled == 'disabled'"
              >
                <span :class="`${o.disabled != 'disabled'?'opacity-1':'opacity-0'}`">{{ o.option }}</span>
              </button>
            </div>
          </draggable>
        </div>
      </div>
      
      <div class="questions-description-image" v-if="currentDescriptionImage">
        <div class="image">
          <img :src="currentDescriptionImage" alt="" />
        </div>
      </div>

    </div>
    
  </div>
</template>

<script>
import { BButton, BAlert, BSpinner } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Confetti from "../../../../public/Confetti.json";
import Lottie from "vue-lottie";
import _ from "lodash";
import draggable from "vuedraggable";
import * as Helpers from "@/Helpers";

export default {
  components: {
    BButton,
    BAlert,
    BSpinner,
    ToastificationContent,
    Lottie,
    draggable,
  },
  data() {
    return {
      questionText: "",
      alertMessageToast: "",
      currentQuestionIndex: 0,
      Confetti,
      confettiOptions: {
        loop: false,
        autoplay: true,
        animationData: Confetti,
      },
      draggedOption: null,
      classes: ["blue", "green", "orange", "purple"],
      dragging: false,
      expressions: [],
      options: [],
      answers: [],
      Helpers,
      currentPlaceholder: 0,
      currentDescriptionImage: null,
      optionMaxCharacterCount: 0,
    };
  },
  methods: {
        releaseOption(e, eIndex){
            if(e.optionIndex != undefined){
                this.$set(this.options, e.optionIndex, {
                    ...this.options[e.optionIndex],
                    disabled: null,
                });
            }

            if(eIndex != undefined){
                this.$set(this.expressions, eIndex, {
                    ...e,
                    expression: "__",
                    btn: "",
                    matched: false,
                });
            }
            
            const firstUnansweredQuestion = this.expressions.filter(
                (exp) => exp.type === "question" && exp.expression != "__"
            );
            this.currentPlaceholder = firstUnansweredQuestion.length > 0 ? eIndex : 0;
        },
        getExpressions() {
          if (this.currentQuestion.patterns && this.currentQuestion.patterns[0].expression) {
            const expression = this.currentQuestion.patterns[0].expression;
            // const parts = this.Helpers.splitTextWithUnderscore(expression);
            const parts = expression.split(",");
    
            this.expressions = parts.map((element, index) => ({
                expression: element,
                type: element === "__" ? "question" : "option",
            }));

            this.answers = this.Helpers.splitValueByCommaAccordingToExpression(this.expressions, this.currentQuestion.patterns[0].answer);
        
            this.currentPlaceholder = this.expressions.findIndex(
                (e) => e.type === "question" && !e.matched
            );

            //   console.log('this.expressions=',this.currentQuestion.patterns[0].answer, JSON.stringify(this.expressions), JSON.stringify(this.Helpers.splitStringAccordingToExpression(this.expressions, this.currentQuestion.patterns[0].answer)));
          }
        },
        getOptions() {
          // console.log('this.currentQuestion.patterns[0].optionsGroups=', JSON.stringify(this.currentQuestion.patterns[0].optionsGroups));
          if (this.currentQuestion.patterns && this.currentQuestion.patterns[0].optionsGroups && this.currentQuestion.patterns[0].optionsGroups.length > 0) {
            const optionsTitle = this.currentQuestion.patterns[0].optionsGroups[0].title;
            const optionsArray = optionsTitle.split(",").map(option => option.trim());

            this.options = _.shuffle(optionsArray).map((option, index) => ({
              option,
              btn: this.classes[index % this.classes.length],
            }));

            if(this.options.length > 0){
              const maxCharObject = _.maxBy(this.options, item => item.option.length);
              if(maxCharObject.option){
                const characterCount = maxCharObject.option.length;
                this.optionMaxCharacterCount = characterCount;
                console.log('this.options=',this.options, characterCount);
              }
            }
          }
        },
    getDescription(){
      if (this.currentQuestion.descriptions && this.currentQuestion.descriptions.length > 0) {
        const imageData = this.currentQuestion.descriptions[0].image;
        if(imageData && imageData.length > 0){
          this.currentDescriptionImage = imageData ? imageData[0].url : null;
        } else {
          this.currentDescriptionImage = null;
        }
      } else {
        this.currentDescriptionImage = null;
      }
    },
    
    startDrag(option, optionIndex) {
      option.optionIndex = optionIndex
      this.draggedOption = option;
    },
    handleDrop(ex, exIndex) {
      console.log('this.draggedOption=', this.draggedOption, ex, exIndex);
            this.$set(this.options, this.draggedOption.optionIndex, {
                ...this.options[this.draggedOption.optionIndex],
                disabled: 'disabled',
            });
            
            // const questionIndex = this.expressions.findIndex(
            //   (e) => e.type === "question" && !e.matched
            // );

            if (exIndex !== -1) {
                this.$set(this.expressions, exIndex, {
                    ...this.expressions[exIndex],
                    expression: this.draggedOption.option,
                    matched: true,
                    btn: this.draggedOption.btn,
                    optionIndex: this.draggedOption.optionIndex,
                });

                this.currentPlaceholder = this.expressions.findIndex(
                    (e) => e.type === "question" && !e.matched
                );

                const allFilled = this.expressions.every((e) => e.type !== "question" || e.matched);
                console.log('this.expressions=',this.expressions, allFilled)

                if (allFilled) {
                    const isCorrect = this.expressions.every((e, index) => 
                        e.expression === this.answers[index].expression
                    );

                    const getEmit = { ...this.currentQuestion, isCorrect };

                    if (!isCorrect) {
                        setTimeout(() => {
                            this.expressions.forEach((e, index) => {
                                if (e.type === "question") {
                                    this.$set(this.expressions, index, {
                                        ...e,
                                        expression: "__",
                                        btn: "",
                                        matched: false,
                                    });
                                }
                            });
                            
                            this.currentPlaceholder = this.expressions.findIndex(
                                (e) => e.type === "question" && !e.matched
                            );

                            this.getOptions();
                        }, 500);
                    }

                    // console.log('getEmit=',getEmit)
                    this.$emit("get-question-response", getEmit);
                }
            }
        },
    onDragChange() {
      // You can handle any additional logic when the order changes
    }
  },
  watch: {
    currentQuestion() {
      this.getExpressions();
      this.getOptions();
      this.getDescription();
    },
  },
  mounted() {
    this.getExpressions();
    this.getOptions();
    this.getDescription();
  },
  props: ["currentQuestion"],
};
</script>

<style>
.drag-and-drop .drag-and-drop-container {
    display: flex;
    gap: clamp(0.5rem, 2vw, 1rem);
    padding: 0 clamp(0.5rem, 2vw, 1rem);
    justify-content: center;
}
.drag-and-drop .question-data {
    gap: clamp(0.5rem, 2vw, 1rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.drag-and-drop .questions-description-image {
    background: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 18%;
    max-width: 200px;
    min-width: 100px;
    width: clamp(100px, 18%, 200px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.drag-and-drop .questions-description-image .image {
    padding: 10px;
    width: 100%;
    text-align: center;
}
.drag-and-drop .questions-description-image .image img {
  width: auto;
  max-width: 100%;
  height: auto;
  object-fit: contain;
}
.drag-and-drop .btn__expressions .btn.btn__option {
    background-color: #fff;
}
.drag-and-drop .btn__expressions .draggable-container,
.drag-and-drop .btn__options .draggable-container {
    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(4rem, 1fr)); */
    gap: 1rem;
    font-size: clamp(1.25rem, 2vw, 2rem);
    justify-content: center;
    display: flex;
    /* flex-wrap: wrap; */
    gap: clamp(0.5rem, 2vw, 1rem);
}
.drag-and-drop .btn__expressions .draggable-container .btn,
.drag-and-drop .btn__options .draggable-container .btn {
    min-width: 4rem;
    min-height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.drag-and-drop .btn__expressions .draggable-container .btn.btn__white {
    background: #fff;
}
.drag-and-drop .btn__expressions .draggable-container .btn.btn__white.option {
    color: #ff0000;
}
.drag-and-drop .btn__expressions .draggable-container .btn.btn__white.question {
    color: #000;
}
.drag-and-drop .btn__expressions .draggable-container .btn
.drag-and-drop .btn__options .draggable-container .btn {
    min-width: clamp(4rem, 8vw, 5rem);
    min-height: clamp(4rem, 8vw, 5rem);
}
.opacity-0 { opacity: 0; }
.opacity-1 { opacity: 1; }
.btn__disabled {
  opacity: 0.3 !important;
}
</style>